@import '../../styles/propertySets.css';

.root {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 52px;
  }
}

.title {
  /* Font */
  /** marketplaceH3FontStyles*/  
font-family: 'Greycliff';
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.5px;
/** end */
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 13px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.rules {
  margin-top: 0;
  margin-bottom: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}
