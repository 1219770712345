@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.root {
}

.pageRoot {
  background-color: white;
  display: flex;
  padding-bottom: 90px;
  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.sectionImages {
}

.heroHeight {
  @media (--viewportMedium) {
    height: calc(0.41 * 100vw);
    max-height: 50vh;
  }
}

.imageWrapperForSectionImage {
  composes: heroHeight;
  background-color: var(--matterColorNegative); /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
}

.actionBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: var(--matterColorNegative);
  background-color: var(--matterColor);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.actionBarForProductLayout {
  margin: 24px;
  width: calc(100% - 48px);

  @media (--viewportMedium) {
    margin: 24px 0 24px 0;
    width: 100%;
  }

  @media (--viewportLarge) {
    margin: 0 0 24px 0;
  }
}

.actionBarForHeroLayout {
  position: absolute;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
}

.ownListingText {
  /**fave-copy-large-bold*/  
font-family: Proxima Nova;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
/** end */
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  /**--marketplaceH4FontStyles*/  
  font-family: 'Greycliff';
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: -0.4000000059604645px;
/** end */
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  /**fave-copy-large-bold*/  
font-family: Proxima Nova;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
/** end */
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--matterColorNegative);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 25px 24px 22px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  composes: heroHeight;
  width: 100%;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  /**marketplaceTinyFontStyles*/  
   font-family: var(--fontFamily);
    font-weight: var(--fontWeightRegular);
    font-size: 13px;
    line-height: 18px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 9.5px;
    margin-bottom: 8.5px;
    -webkit-font-smoothing: subpixel-antialiased;

    @media (--viewportMedium) {
      line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 10.5px;
      margin-bottom: 13.5px;
    }
/** end */
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentWrapperForHeroLayout {
  @media (--viewportMedium) {
    max-width: 1056px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.mainColumnForProductLayout {
  padding: 16px;
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    padding: 24px;
    padding-right: 80px;
    /* contentContainer's full width - (orderColumn + paddings + margin) */
    max-width: calc(100% - 500px);
    flex-basis: calc(100% - 500px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForProductLayout {
  z-index: 1;
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    border-left: 1px solid var(--fave-color-purple-s50);
    background: var(--fave-gradient-lightpurple-listing);
    display: block;
    flex-basis: 500px;
    flex-shrink: 0;
    padding: 24px;
    padding-left: 80px;
  }
}

.shopPoliciesContent {
  white-space: pre-wrap;
}

.shopPoliciesLink {
  width: fit-content;
  padding-top: 24px;
  &:hover {
    cursor: pointer;
    color: var(--fave-color-primary-default);
  }
}

.contentWrapperForProductLayout {
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  @media (--viewportMedium) {
    width: 100%;
    height: 100%;
    max-width: 1056px;
  }

  @media (--viewportLarge) {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: stretch;
    max-width: 1428px;
  }
}

.mainColumnForHeroLayout {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 50px;
    margin-bottom: 0px;
    /* contentContainer's full width - (orderColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForHeroLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    display: block;
    margin-top: 79px;
    margin-left: 60px;
    border-left: 1px solid var(--matterColorNegative);
    padding-left: 60px;
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.orderPanel {
  @media (--viewportLarge) {
    position: sticky;
    top: 108px;
  }
}

.productOrderPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;
    position: sticky;
    top: 108px;
  }
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.sectionHeading {
  margin-top: 22px;
  margin-bottom: 34px;

  @media (--viewportMedium) {
    display: flex;
    margin-bottom: 31px;
  }
}
.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  /**marketplaceModalTitleStyles*/  
   font-weight: var(--fontWeightBold);
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.5px;
    margin: 0;
    font-weight: var(--fontWeightBold);

    @media (--viewportMedium) {
      font-weight: var(--fontWeightBold);
      line-height: 40px;
      letter-spacing: -0.9px;
      margin: 0;
    }
/** end */;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  /**marketplaceH5FontStyles */
    font-family: 'Greycliff';
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
/** end */
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  /**marketplaceModalTitleStyles*/  
   font-weight: var(--fontWeightBold);
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.5px;
    margin: 0;
    font-weight: var(--fontWeightBold);

    @media (--viewportMedium) {
      font-weight: var(--fontWeightBold);
      line-height: 40px;
      letter-spacing: -0.9px;
      margin: 0;
    }
/** end */;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  /**marketplaceH5FontStyles */
    font-family: 'Greycliff';
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
/** end */
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.authorNameLink {
  color: var(--matterColor);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /** marketplaceLinkStyles */
 /* Position and dimensions */
    display: inline;
    margin: 0;
    padding: 0;

    /* Borders */
    border: none;

    /* Colors */
    color: var(--marketplaceColor);

    /* Text size should be inherited */
    text-decoration: none;

    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
/** end */
  /**marketplaceH5FontStyles */
    font-family: 'Greycliff';
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
/** end */
  margin: 0;
}

.sectionDescription {
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }

  ul {
    margin: left 12px;
    li {
      list-style-type: disc;
    }
  }
  ol {
    margin-left: 12px;
    li {
      list-style-type: decimal;
    }
  }

  
}

.sectionTitle {
  /* Font for sections titles */
  /**fave-copy-default-bold*/  
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
/** end */
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.11px;
}

.detailsTitle,
.descriptionTitle {
  composes: sectionTitle;
  margin-top: 0;
  margin-bottom: 12px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.shippingTitle {
  composes: sectionTitle;
  margin-top: 24px;
  margin-bottom: 12px;
  @media (--viewportMedium) {
    margin-top: 24px;
    margin-bottom: 8px;
  }
}

.description {
  padding: 1px 0 5px 0;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: var(--fontWeightRegular);

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    padding: 3px 0 5px 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.sectionFandom {
  padding: 0 24px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.fandomTitle {
  composes: sectionTitle;
  margin-top: 0;
  margin-bottom: 12px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.fandom {
  padding: 1px 0 5px 0;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: var(--fontWeightRegular);

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    padding: 3px 0 5px 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.sectionDetails {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.details {
  padding-bottom: 1px;
  margin: 0;
}

.detailsRow {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 1px 0 4px 0;
  line-height: 36px;
  list-style: none;
  font-weight: var(--fontWeightRegular);

  &:not(:last-child) {
    border-bottom: 1px solid var(--matterColorNegative);
  }

  @media (--viewportMedium) {
    padding: 7px 0 0px 0;
    line-height: 40px;
  }
}

.detailLabel {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.09px;
}

.sectionFeatures {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 52px;
  }
}

.featuresTitle {
  composes: sectionTitle;

  margin-top: 0;
  margin-bottom: 16px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.locationTitle {
  composes: sectionTitle;

  margin-top: 0;
  margin-bottom: 20px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 24px;
  }
}

.sectionReviews {
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    padding-bottom: 5px;
    margin-bottom: 0;
  }
}

.reviewsTitle {
  composes: sectionTitle;

  margin: 52px 0 20px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    padding: 5px 0 3px 0;
    margin: 56px 0 26px 0;
  }
}

.sectionAuthor {
  position: relative;
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.aboutProviderTitle {
  composes: sectionTitle;

  margin: 54px 0 23px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 64px 0 24px 0;
    padding: 5px 0 3px 0;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.productGallery {
  margin: 0;

  @media (--viewportMedium) {
    margin: 24px 0 24px 0;
  }
  @media (--viewportLarge) {
    margin: 0 0 48px 0;
    padding-bottom: 4px;
  }
}

.productMobileHeading {
  @media (--viewportLarge) {
    display: none;
  }
}

.productDesktopHeading {
  display: none;
  @media (--viewportLarge) {
    display: block;
  }
}

.productSideContent {
}

.productBookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

/* section heading to match Order Panel */

.categoryPill {
  /**fave-copy-xsmall-bold*/  
    font-family: Proxima Nova;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0.3px;
/** end */;
  padding: 8px;
  height: 32px;
  border: 1px solid var(--fave-color-purple-s300);
  border-radius: var(--borderRadius);
  color: var(--fave-color-purple-s300);
  width: fit-content;
}

.pillsContainer {
  display: flex;
  height: 32px;
  margin-bottom: 8px;
}

.goldPill {
  margin-left: 12px;
}
.sectionHeadingAuthor {
  display: flex;
  flex-direction: row;
  width: 100%;
  /**fave-copy-default-bold*/  
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
/** end */
  color: var(--fave-color-purple-s500);
  margin-bottom: 16px;
  margin-top: 16px;
}

.sectionHeadingPrice {
  /**fave-copy-large-bold*/  
font-family: Proxima Nova;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
/** end */
  color: var(--fave-color-black-s500);
  margin-bottom: 12px;
}

.sectionHeadingTitle {
  margin-bottom: 8px;
  /** marketplaceH1FontStyles*/  
    font-family: 'Greycliff';
    font-size: 32px;
    font-weight: 700;
    line-height: 37px;
    letter-spacing: -1px;
/** end */
  color: black;
}

.sectionHeadingAuthorNameLink {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.link:hover {
  text-decoration: none;
}

.sectionHeadingAuthorShopName {
  /**fave-copy-default-bold*/  
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
/** end */
  color: var(--fave-color-purple-s500);
  margin-left: 8px;
}
